/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./src/styles/global.scss";
import "./src/styles/toastify.css";
